@font-face {
    font-family: "Lato";
    src: url("../fonts/Lato-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Lato-Italic";
    src: url("../fonts/Lato-Italic.ttf") format("truetype");
}

@font-face {
    font-family: "Lato-Black";
    src: url("../fonts/Lato-Black.ttf") format("truetype");
}

@font-face {
    font-family: "Lato-Bold";
    src: url("../fonts/Lato-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Lato-Light";
    src: url("../fonts/Lato-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Lato-Thin";
    src: url("../fonts/Lato-Thin.ttf") format("truetype");
}



body {
    background-color: white;
    font-family: Lato;
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
a {
    line-height: 1;
    word-break: break-word;
    word-wrap: break-word;
    font-weight: 200;
}

a {
    text-decoration: none;
    cursor: pointer;
}

h4 {
    text-transform: uppercase;
    font-family: Lato-Bold !important;
    font-weight: 900 !important;
    font-size: 1.2rem;
    color: white;
    letter-spacing: 2px;
}

/* LOADERS */
/* HTML: <div class="loader"></div> */
.center-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20rem;
}

.loader {
    width: 50px;
    aspect-ratio: 1;
    display: grid;
    border: 4px solid #0000;
    border-radius: 50%;
    border-color: #ccc #0000;
    animation: l16 1s infinite linear;
}

.loader::before,
.loader::after {
    content: "";
    grid-area: 1/1;
    margin: 2px;
    border: inherit;
    border-radius: 50%;
}

.loader::before {
    border-color: #3cdbcd #0000;
    animation: inherit;
    animation-duration: .5s;
    animation-direction: reverse;
}

.loader::after {
    margin: 8px;
}

@keyframes l16 {
    100% {
        transform: rotate(1turn)
    }
}

.mobile {
    color: black !important;
}

/* FONTS */
.font-1 {
    font-size: 3rem;
}

.font-2 {
    font-size: 2.4rem;
}

.font-3 {
    font-size: 1.8rem;
}

.font-4 {
    font-size: 1.2rem;
}

.font-45 {
    font-size: 0.7rem;
}

.font-5 {
    font-size: 0.6rem;
}

/* BACKGROUNDS */
.bg-ligth-gray {
    background-color: #eeeeee;
}

.bg-dark-gray {
    background-color: #1e1e1e;
}

.bg-dark-footer {
    background-color: #f0f0f0;
}

.bg-dark-text-box {
    background-color: #3cdbcd;
}

.bg-text {
    background-color: #485262;
}

.bg-pattern-1 {
    background-image: url("../img/bg/pattern.jpg");
    background-repeat: repeat;
}

.bg-pattern-footer {
    background-image: url("../img/footer//Tectura-footer.png");
    background-repeat: repeat;
}


/* COLORS */
.cl-ligth-gray {
    color: #eeeeee;
}

.cl-dark-gray {
    color: #1e1e1e;
}

.cl-dark-footer {
    color: #f9fafa;
}

.cl-dark-text-box {
    color: #00ab6c;
}

.cl-text {
    color: #485262;
}

.brown-text {
    color: #606060;
}

/* TEXT */
.txt-bold {
    font-family: Lato-Bold;
}

/* BTN */
.btn {
    color: transparent !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

.btn:active {
    color: transparent !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

/* VIEW MORE */
.view-more {
    background-color: #1e1e1e;
    margin-bottom: 0rem;
    color: white;
    font-family: Lato-Bold;
    text-transform: uppercase;
    text-align: center;
    height: 3.4rem;
}

.view-more .more-container {
    padding-top: 0.2rem;
}

.view-more button {
    padding-top: 0px;
    font-weight: 600;
    letter-spacing: 2px;
    font-size: 0.8rem;
    color: white !important;
    text-transform: uppercase;
    text-decoration: none;
}



@media (max-width: 991px) {
    .view-more {
        height: 3.5rem;
    }

    .view-more a {
        color: white;
    }

    .view-more i {
        font-size: 1rem;
    }
}

@media (max-width: 767px) {
    .view-more {
        height: 3.3rem;
    }

    .view-more .more-container {
        padding-top: .1rem;
    }

    .view-more a {
        color: white;
        font-size: .7rem;
    }

    .view-more i {
        font-size: 1rem;
    }
}

/* MENU */
.menu {
    padding: 0.5rem;
    background: transparent !important;
    border-bottom: 1px solid white;
    transition: background-color 0.5s ease, height 0.3s ease;
}

.scroll {
    padding: 0.5rem;
    background: rgba(0, 0, 0, .5) !important;
    border-bottom: 1px solid white;
    height: 4rem;
    transition: background-color 0.5s ease, height 0.3s ease;
}

.scroll a {
    font-size: 0.8rem !important;
}

.scroll img {
    width: 2.3rem !important;
}

.menu-dark {
    color: black !important;
    background: white !important;
    transition: background-color 0.5s ease, height 0.3s ease;
}

.menu-dark a {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1rem;
    color: black !important;
    transition: background-color 0.5s ease, height 0.3s ease;

}

.menu-dark-scroll {
    background: rgba(255, 255, 255, 0.5) !important;
    transition: background-color 0.5s ease, height 0.3s ease;
}

.menu-dark-scroll a {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1rem;
    color: black !important;
}



.menu .container-nav {
    margin-left: 3rem;
    margin-right: 3rem;
}

.menu img {
    width: 3.5rem;
}

.menu .icon-toggler img {
    width: 1.5rem;
}

.menu .navbar-toggler {
    border-radius: 0px;
    border: none;
}

.menu .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
}

.menu .toggle {
    width: 1.5rem !important;
}

.nav-link:focus,
.nav-link:hover {
    color: #000;
}

.menu .navbar-nav a {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1rem;
    color: #000;
}

.menu .navbar-nav .title {
    color: #3cdbcd !important;
    font-weight: 600;
}

.menu .navbar-nav .sub-title {
    color: #000 !important;
    font-size: 0.8rem;
    text-transform: capitalize;
    font-family: Lato-Bold;
}

.menu .navbar-nav .list ul {
    list-style: none;
}

.menu a {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1rem;
    color: white;
}

navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none !important;
}

@media (max-width: 991px) {
    .menu {
        background-color: white !important;

    }

    .menu .container-nav {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .menu ul {
        margin-top: 1rem;
    }

    .menu li {
        padding: 0.2rem;
    }

    .menu a {
        color: #1e1e1e;
    }
}

@media (max-width: 767px) {
    .menu .container-nav {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

/* FOOTER */
footer {
    height: 30;
    width: auto;
}

.footer .footer-container {
    /* margin-left: 8rem;
    margin-right: 8rem; */
}

.footer .colum {
    padding: 2rem;
}

.footer ul li {
    padding: 1px;
    text-transform: capitalize;
    font-family: Lato;
    text-align: end;
    margin-right: 30%;
    font-size: .8rem;
}

.footer .newsletter p {
    text-transform: uppercase;
    font-weight: 600px;
}

.footer .suscribe {
    padding-left: 1rem;
}

.footer .suscribe p {
    text-transform: uppercase;
    font-size: .7rem;
    letter-spacing: 1px;
    margin-bottom: .5rem;
}

.footer input {
    font-family: Lato-Italic;
    height: 3rem;
    border-radius: 20px;
    border: 1px solid #606060
}

.custom-input-group {
    position: relative;
}

.custom-input-group input.form-control {
    padding-right: 38px;
}

.custom-input-group {
    border-collapse: separate;
}

.custom-input-group .btn {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    color: white !important;
    background-color: #3cdbcd !important;
    width: 30%;
    text-transform: uppercase;
    height: 3rem;
    border-radius: 20px;
    border: 1px solid #606060;
}


.footer .dashed-border {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px dashed #606060;
    height: 100px;
    margin: 0 auto;
    margin-top: 2.5rem;
}

.footer .copyrigth {
    background-color: #897d7d;
    height: 50px;
}

.footer .copyrigth p {
    padding: 20px;
    color: white;
}

@media (max-width: 992px) {
    .footer-container {
        padding-top: 3rem;
    }

    .footer .colum {
        padding: 0rem !important;
    }


    .footer ul {
        text-align: center;
    }

    .footer ul li {
        text-align: center;
        margin-right: 0px;
        font-size: 1rem;
    }

    .footer p {
        text-align: center;
    }

    .footer .colum {
        padding: 2rem;
    }

    .footer .dashed-border {
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: transparent;
        height: 100px;
        margin: 0 auto;
        margin-top: 0px !important;
    }

}

@media (max-width: 768px) {
    .footer .footer-container {
        margin-left: 2rem;
        margin-right: 2rem;
    }
}

@media (max-width: 576px) {
    .footer .footer-container {
        margin-left: 2rem;
        margin-right: 2rem;
    }
}

/* HOME */
.home-slide .carousel-indicators button {
    margin-right: 0.7em;
}

.home-slide .carousel-control-prev {
    margin-left: -2rem;
}

.home-slide .carousel-control-prev img {
    width: 15%;
}

.home-slide .carousel-control-next {
    margin-left: -2rem;
}

.home-slide .carousel-control-next img {
    width: 15%;
}

@media (max-width: 992px) {
    .home-slide .carousel-control-next {
        margin-top: 5%;
    }

    .home-slide .carousel-control-prev {
        margin-top: 5%;
    }
}

@media (max-width: 768px) {
    .home-slide .carousel-control-next {
        margin-top: 10%;
    }

    .home-slide .carousel-control-prev {
        margin-top: 10%;
    }
}

@media (max-width: 576px) {
    .home-slide .carousel-control-next {
        margin-top: 5%;
        margin-right: 0rem;
    }

    .home-slide .carousel-control-prev {
        margin-top: 5%;
        margin-left: 0rem;
    }

    .home-slide .carousel-control-prev img {
        width: 30%;
    }

    .home-slide .carousel-control-next img {
        width: 30%;
    }
}

.img-info-home .bg-img-home {
    position: relative;
    margin-top: 1rem;
}

.img-info-home .bg-img-home .img-content {
    width: 100%;
    display: flex;
    position: relative;
}

.img-info-home .bg-img-home .img-content img {
    box-shadow: 0px 2px 5px black;
}

.img-info-home .bg-img-home .img-content .img-pd-l {
    width: 30%;
    height: 8rem;
    background-color: black;
    margin-top: 2%;
    z-index: -1;
}

.img-info-home .bg-img-home .img-content .img-pd-r {
    width: 30%;
    height: 8rem;
    background-color: #cccccc;
    margin-top: 2%;
    z-index: -1;
}

.img-info-home .bg-img-home .img-content .img-m-l {
    margin-right: 1%;
}

.img-info-home .bg-img-home .img-content .img-m-r {
    margin-left: 1%;
}

.img-info-home .bg-img-home .img-content p {
    margin-top: -10%;
    text-align: right;
    margin-right: 4%;
    color: white;
    text-transform: uppercase;
    font-family: Lato-bold;
    font-weight: 600;
    font-size: 0.8rem;
}

.img-info-home .bg-img-home .img-content .img-pd-r p.book {
    margin-top: 3.5rem;
    text-align: center;
    color: black;
    text-transform: uppercase;
    font-family: Lato-bold;
    font-weight: 400;
    font-size: 0.8rem;
    text-decoration: none;
}

@media (max-width: 992px) {
    .img-info-home {
        margin-bottom: 10%;
    }

    .img-info-home .bg-img-home .img-content {
        width: 100%;
        display: block;
        text-align: center;
    }

    .img-info-home .bg-img-home .img-content .img-m-l {
        margin-right: 0% !important;
    }

    .img-info-home .bg-img-home .img-content .img-m-r {
        margin-left: 0% !important;
    }

    .img-info-home .bg-img-home .img-content .img-pd-r {
        /* margin: auto;
        width: 50%;
        height: 10rem; */
        display: none;
    }

    .img-info-home .bg-img-home .img-content .img-pd-l {
        /*  margin: auto;
        width: 50%;
        height: 10rem; */
        display: none;
    }

    .img-info-home .bg-img-home .img-content p {
        margin-top: -10%;
        margin-right: 25%;
    }

    .img-info-home .bg-img-home .img-content img {
        margin-top: 5%;
        margin-bottom: 5%;
    }

}

@media (max-width: 768px) {
    .img-info-home {
        margin-bottom: 4%;
    }

    .img-info-home .bg-img-home .img-content {
        padding: 8%;
    }

    .img-info-home .bg-img-home .img-content p {
        margin-top: -15%;
        margin-right: 10%;
    }

    .img-info-home .bg-img-home .img-content img {
        margin-top: 7%;
        margin-bottom: 7%;
    }
}

.home-social {
    text-align: center;
    padding: 3.5rem;
}

.home-social p {
    text-transform: uppercase;
}

.home-social a {
    padding: 1.5rem;
}

.home-social a img {
    width: 20px;
}

@media (max-width: 992px) {
    .home-social {
        padding: 0.5rem;
    }
}

.view-gallery {
    text-align: center;
}

.view-gallery button {
    text-transform: uppercase;
    padding: 1.2rem;
    width: 25%;
    background-color: white;
    color: #1e1e1e !important;
    box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.2);
}

.view-gallery button:hover {
    background-color: #3cdbcd !important;
    color: white !important;
}

.view-gallery button:active {
    background-color: #3cdbcd !important;
    color: white !important;
}

@media (max-width: 992px) {
    .view-gallery button {
        margin-top: 2rem;
        width: 50%;

    }
}

@media (max-width: 768px) {
    .view-gallery button {
        margin-top: 2rem;
        width: 90%;

    }
}

.instagram .card {
    --bs-card-border-width: 0px;
}

.instagram .card h5 {
    font-family: Lato-Bold;
    font-size: 1rem;
    text-transform: capitalize;
}

.instagram .content-box {
    box-shadow: 0px 0px 10px#ccc;
    border-radius: 5px;
}

.instagram .content-box video {}

.instagram .card-content {
    display: flex;
    justify-content: flex-start;
}

.instagram .card .card-text {
    font-size: 0.7rem;
    text-transform: capitalize;
}

.instagram .card .likes {
    padding-right: 1rem;
}

.instagram .card .date {
    padding-left: 1rem;
}

.instagram .card .card-img-top {
    width: 100%;
    height: 18rem;
    object-fit: cover;
}

/* PHOTOGRAPHY */
.banner-photography {
    width: 100%;
    /* height: 100vh; */
}

.banner-photography .row {
    --bs-gutter-x: 0rem !important;
    position: relative;

}

.banner-photography .row .vertical-gallery {
    height: 40rem;
}

.banner-photography .ul-carousel-container {
    position: relative;
}

.banner-photography .ul-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

.with-margin {
    background-color: rgba(0, 0, 0, 0.6);
    margin-right: 2px;
}

/* drop down menu */
.banner-photography .ul-container ul li a {
    height: 2.5rem;
    margin-top: 15px;
    color: white;
    font-family: Lato-Bold;
    letter-spacing: 7px;
    font-size: .85rem;
    font-weight: 100;
}

.banner-photography .nav-link {
    text-transform: uppercase;
}

.banner-photography .dropdown-toggle::after {
    margin-left: 10% !important;
    border-top: .5em solid;
}

.bg-all-videos {
    background-color: #1e1e1e;
    margin-right: 2px;
    height: 2rem;
    padding-top: .2rem;
}

.banner-photography .all-videos {
    font-size: .7rem;
    letter-spacing: 2px;
    font-family: Lato-Bold;
}

/* drop down desplegable */
.banner-photography .dropdown-menu {
    background-color: rgba(0, 0, 0, 0.6);
    border-width: none !important;
    --bs-dropdown-item-padding-x: 30%;
    --bs-dropdown-item-padding-y: 1rem;
    width: 100%;
    height: 36.5rem;
    border-radius: 0px;
}

.banner-photography .dropdown-menu .dropdown-item:focus,
.dropdown-item:hover {
    color: white;
    background-color: transparent !important;
}

.banner-photography .dropdown-menu a {
    text-transform: uppercase;
    text-align: justify;
    margin-left: -4rem;
}

.banner-photography .carousel-container img {
    width: auto !important;
    height: 40rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
}


.banner-photography .carousel-indicators button {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .5) !important;
    border: 1px solid rgba(0, 0, 0, .5) !important;
}

.banner-photography .carousel-indicators button.active {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .5) !important;
    border: 2px solid #fff !important;
}

.banner-photography .carousel-indicators button:active {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .5) !important;
    border: 2px solid #fff !important;
}

@media (max-width: 992px) {
    .banner-photography .row .vertical-gallery {
        width: 100%;
        height: 6rem;
        display: flex;
    }

    .banner-photography .dropdown-menu {
        height: 38.8rem;
    }

    .banner-photography .carousel-container img {
        height: 35rem;
    }

    .banner-photography .ul-container ul li a {
        height: 2rem;
        margin-top: 10px;
        font-size: .75rem;
    }

}

@media (max-width: 768px) {
    .banner-photography .ul-container {
        position: relative;
        background-color: #1e1e1e;
    }

    .banner-photography .carousel-container img {
        height: 30rem;
    }

    .banner-photography .ul-container ul li a {
        height: 2.5rem;
        margin-top: 5px;
        font-size: 0.8rem;
    }

    .banner-photography .dropdown-menu {
        background-color: rgba(0, 0, 0, 0.8);
        border-width: none !important;
        --bs-dropdown-item-padding-x: 30%;
        --bs-dropdown-item-padding-y: 1rem;
        width: 100%;
        height: 55vh;
        border-radius: 0px;
    }

    @media (max-width: 576px) {
        .banner-photography .carousel-container img {

            height: 15rem;
        }
    }
}

/* content photography */
.content-photography {
    /* padding: 3rem 3rem; */

}

.content-photography .elemements-ph {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.content-photography .img-r {
    text-align: right;
}

.content-photography .content-r {
    margin-top: 6.9rem;
    text-align: left;
}

.content-photography .content-l {
    margin-top: 6.9rem;
    text-align: right;
}

.content-photography .content-r p {
    color: white;
    font-size: .8rem;
    text-align: justify;
    max-width: 70%;
    line-height: 1.5rem;
    font-weight: 500;
}

.content-photography .content-l p {
    color: white;
    font-size: .85rem;
    line-height: 1.5rem;
    font-weight: 500;
    text-align: justify;
    margin-left: 30%;
}

.content-photography .accordion {
    --accordion-bg: #1e1e1e;
}

.content-photography .card {
    --bs-card-spacer-y: 0rem;
    --bs-card-spacer-x: 0rem;
    --bs-card-border-radius: 0px;
    --bs-card-border-width: 0px;
}

.content-photography .card-header:first-child {
    border-radius: 0 0 0 0;
}

@media (max-width: 992px) {
    .content-photography {
        /*  padding: 3rem 1rem; */
    }

    .content-photography .img-r {
        text-align: center;
    }

    .content-photography .elemements-ph {
        text-align: center;
    }

    .content-photography .content-r {
        margin-top: 2rem;
        text-align: left;
        margin-bottom: 2rem;
        margin-left: 5rem;
    }

    .content-photography .content-l {
        margin-top: 2rem;
        text-align: left;
        margin-bottom: 2rem;
        margin-left: 5rem;
    }

    .content-photography .content-l p {
        margin-left: 0%;
        max-width: 70%;
    }
}

@media (max-width: 768px) {
    .content-photography .content-r {
        margin-top: 2rem;
        text-align: left;
        margin-bottom: 2rem;
        margin-left: 0rem;
    }

    .content-photography .content-l {
        margin-top: 2rem;
        text-align: left;
        margin-bottom: 2rem;
        margin-left: 0rem;
    }

    .content-photography .content-r p {
        margin-left: 0%;
        max-width: 100%;
    }

    .content-photography .content-l p {
        margin-left: 0%;
        max-width: 100%;
    }
}

/* VIDEOGRAPHY */
.video-horizontal {
    width: 100%;
}

.video-center {
    text-align: center;
}

.video-vertical {
    width: 50%;
}

.banner-photography .row .vertical-videography a {
    color: white;
}

/* thumbnail video */
.videography-thumbnail .thumbnail {
    position: relative;
    background-color: black;

}

.videography-thumbnail .thumbnail img {
    width: 100%;
    height: auto;
}

.videography-thumbnail .selected-image {
    top: 0;
    left: 0;
    z-index: 100;
    opacity: 0.5;
}

.videography-thumbnail .thumbnail h6 {
    position: absolute;
    color: white;
    z-index: 2;
    bottom: 1rem;
    font-family: Lato-bold;
    font-size: 0.8rem;
    letter-spacing: 4px;
    text-transform: uppercase;
    padding-left: 1.5rem;
}

.videography-thumbnail .title {
    position: sticky;
    top: 0;
    background-color: #282828;
    /* Puedes cambiar el color de fondo según tus preferencias */
    padding: 1rem;
    margin: 0;
    z-index: 10;
}

.videography-thumbnail .title h2 {
    color: #ffffff;
    font-family: Lato-bold;
    font-size: 0.7rem;
    letter-spacing: 5px;
    text-transform: uppercase;
}

.videography-video .carousel-control-prev {
    width: 2rem;
    height: 2rem;
}

.videography-video .carousel-control-next {
    width: 2rem;
    height: 2rem;
}

@media (max-width: 992px) {
    .videography-thumbnail {
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
    }

    .videography-thumbnail .thumbnail {
        display: inline-block;
    }

    .videography-thumbnail .thumbnail img {
        width: 100%;
        height: 20vh;
    }
}

@media (max-width: 768px) {

    .videography-thumbnail .thumbnail img {
        width: 100%;
        height: 15vh;
    }

    .videography-thumbnail .thumbnail h6 {
        color: white;
        bottom: 0.5rem;
        font-size: 0.6rem;
        letter-spacing: 2px;
        text-transform: uppercase;
        padding-left: 1rem;
    }
}

/* DESIGN PAGE */
.design .container-design {
    max-width: 1600px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.design .box-title {
    background-color: white;
    padding-left: 6rem;
    padding-right: 3rem;
}

.design .row {
    --bs-gutter-x: 0rem;
    position: relative;
}

.design .box-title h3.main-title {
    padding-top: 7rem;
    padding-bottom: 1rem;
    color: #000000;
    font-family: Lato-Black;
    font-size: 1.6rem;
    text-transform: uppercase;
    z-index: 1;
}

.design .box-title h3.ghost-title {
    padding-top: 4rem;
    padding-left: 4rem;
    position: absolute;
    top: 0;
    left: 0;
    font-family: Lato-Black;
    font-size: 5rem;
    color: rgba(0, 0, 0, 0.1);
    z-index: 0;
}

.design .box-title p {
    font-family: Lato-Light;
    font-size: 1rem;
    line-height: 2rem;
    text-align: justify;
    padding-bottom: 1rem;
}

.design .box-title button {
    color: white !important;
    background-color: #3cdbcd !important;
    width: 10rem;
    font-family: Lato-Bold;
}

.design .box-img img {
    width: 100%;
}

.design .black {
    background-color: #1e1e1e !important;
}

.design .black .box-img {
    display: flex;
    align-items: center;
    height: 70vh;
    background-color: #1e1e1e;
}

.design .black .box-title {
    background-color: #1e1e1e;
    padding-left: 3rem;
    padding-right: 3rem;
}

.design .black .box-title h3.main-title {
    padding-top: 7rem;
    padding-bottom: 1rem;
    color: white;
    font-family: Lato-Black;
    font-size: 1.6rem;
    text-transform: uppercase;
    z-index: 1;

}

.design .black .box-title h3.ghost-title {
    padding-top: 4rem;
    padding-left: 51%;
    position: absolute;
    top: 0;
    left: 0;
    font-family: Lato-Black;
    font-size: 5rem;
    color: rgba(255, 255, 255, 0.1);
    z-index: 0;
}

.design .black .box-title p {
    color: white;
}

.design .black .box-img img {
    width: 90%;
}

@media (max-width: 992px) {
    .design .box-title {
        padding-top: 8rem;
        padding-left: 5rem;
        padding-right: 5rem;
        padding-bottom: 8rem;
    }

    .design .box-title h3.main-title {
        padding-top: 0rem;
        padding-bottom: 1rem;
    }

    .design .box-title h3.ghost-title {
        padding-top: 77.5%;
        padding-left: 2rem;
    }

    .design .black .box-title {
        padding-top: 8rem;
        padding-left: 5rem;
        padding-right: 5rem;
        padding-bottom: 8rem;
    }

    .design .black .box-img {
        display: block;
        height: 100%;
    }

    .design .black .box-title h3.main-title {
        padding-top: 0rem;
        padding-bottom: 1rem;
    }

    .design .black .box-title h3.ghost-title {
        padding-top: 77.5%;
        padding-left: 2rem;
    }

    .design .black .box-img img {
        width: 100%;
    }
}

@media (max-width: 576px) {
    .design .box-title {
        padding-top: 4rem;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 4rem;
    }

    .design .box-title h3.main-title {
        padding-top: 0rem;
        padding-bottom: 0rem;
    }

    .design .box-title h3.ghost-title {
        padding-top: 78.5%;
        padding-left: 1rem;
        font-size: 3rem;
    }

    .design .black .box-title {
        padding-top: 4rem;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 4rem;
    }

    .design .black .box-img {
        display: block;
        height: 100%;
    }

    .design .black .box-title h3.main-title {
        padding-top: 0rem;
        padding-bottom: 0rem;
    }

    .design .black .box-title h3.ghost-title {
        padding-top: 78.5%;
        padding-left: 1rem;
        font-size: 3rem;
    }

    .design .black .box-img img {
        width: 100%;
    }
}